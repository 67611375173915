//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PlacePermissions } from '@/modules/place/place-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-place-view-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      record: 'place/view/record',
      loading: 'place/view/loading',
      destroyLoading: 'place/destroy/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar'
      // return localStorage.getItem('language') == 'ar'
    },
    hasPermissionToEdit() {
      return new PlacePermissions(this.currentUser).edit;
    },

    hasPermissionToImport() {
      return new PlacePermissions(this.currentUser).import;
    },

    hasPermissionToDestroy() {
      return new PlacePermissions(this.currentUser).destroy;
    },

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },
  },

  methods: {
    ...mapActions({
      doDestroy: 'place/destroy/doDestroy',
    }),

    async doDestroyWithConfirm() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(this.record.id);
      } catch (error) {
        // no
      }
    },
  },
};
