//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import PlaceViewToolbar from '@/modules/place/components/place-view-toolbar.vue';
import { PlaceModel } from '@/modules/place/place-model';
import { FormSchema } from '@/shared/form/form-schema';
import moment from 'moment';

const { fields } = PlaceModel;

const formSchema = new FormSchema([
  fields.id,
  fields.placeName,
  fields.description,
  fields.placePictures,
  fields.cityId,
  fields.placeType,

  fields.address,
  fields.lat,
  fields.lng,
  fields.radius,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.notifications,
]);
export default {
  name: 'app-place-view-page',

  props: ['id'],

  components: {
    [PlaceViewToolbar.name]: PlaceViewToolbar,
  },

  // mapGetters to return record , loading

  computed: {
    ...mapGetters({
      record: 'place/view/record',
      loading: 'place/view/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),

    // this computed  for enableRtl

    isRTL() {
      return this.currentLanguageCode == 'ar';
      // return localStorage.getItem('language') == 'ar';
    },

    fields() {
      return fields;
    },
  },

  data() {
    return {
      model: null,
      center: { lat: null, lng: null },
      dense: false,
      markers: [
        {
          position: { lat: 31.2229972, lng: 29.9450273 },
        },
      ],
      autocomplete: '',
      currentPlace: null,
      places: [],
    };
  },

  async created() {
    // doFind function take an id and search for that place
    // first : initialize values with record
    // set center of lat and lng

    await this.doFind(this.id);
    console.log(this.record);

    this.model = formSchema.initialValues(this.record);
    this.center.lat = parseFloat(this.model.lat);
    this.center.lng = parseFloat(this.model.lng);
  },

  methods: {
    // mapAction call the  function from store { doFind }

    ...mapActions({
      doFind: 'place/view/doFind',
    }),

    // display data into form fields

    presenter(record, fieldName) {
      return PlaceModel.presenter(record, fieldName);
    },
    // presenter(row, fieldName) {
    //   const val = PlaceModel.presenter(row, fieldName);
    //   return val ? val : 'ـــ';
    // },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = PlaceModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = PlaceModel.presenter(row, fieldName);
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = PlaceModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(PlaceModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = PlaceModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    //  this functions for map and already defined in place form page

    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };

        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
      );
    },
    updateCoordinates(location) {
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
  },
};
